<template>
  <header class="app-header fixed-top">
    <div class="app-header-inner">
      <div class="container-fluid py-2">
        <div class="app-header-content">
          <div class="row justify-content-between align-items-center">
            <div class="col-auto">
              <a
                id="sidepanel-toggler"
                class="sidepanel-toggler d-inline-block d-xl-none"
                href="#"
                @click="onMenuClick()"
              >
                <i class="fas fa-bars"></i>
              </a>
            </div>

            <div class="app-utilities col-auto">
              <div class="app-utility-item">
                <span class="text-muted">{{org}}</span>
              </div>
              <div class="app-utility-item app-user-dropdown dropdown">
                <a
                  class="dropdown-toggle"
                  id="user-dropdown-toggle"
                  data-bs-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-expanded="false"
                >
                  <i class="fas fa-user-circle lead"></i>
                </a>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="user-dropdown-toggle"
                >
                  <li>
                    <a class="dropdown-item" href="#" @click="switchOrg"
                      >Switch Org</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="#" @click="logOut"
                      >Log Out</a
                    >
                  </li>
                </ul>
              </div>
              <!--//app-user-dropdown-->
            </div>
            <!--//app-utilities-->
          </div>
          <!--//row-->
        </div>
        <!--//app-header-content-->
      </div>
      <!--//container-fluid-->
    </div>
    <!--//app-header-inner-->
    <div id="app-sidepanel" class="app-sidepanel">
      <div id="sidepanel-drop" class="sidepanel-drop"></div>
      <div class="sidepanel-inner d-flex flex-column">
        <a
          href="#"
          id="sidepanel-close"
          class="sidepanel-close d-xl-none"
          @click="onMenuClose()"
          >&times;</a
        >
        <div class="app-branding">
          <router-link class="app-logo" to="/"
            ><img
              class="logo-icon me-2"
              :src="require('@/assets/logo.png')"
              alt="logo"
            /><span class="logo-text">CMS</span></router-link
          >
        </div>
        <!--//app-branding-->

        <nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1">
          <ul class="app-menu list-unstyled accordion" id="menu-accordion">
            <li class="nav-item">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <router-link class="nav-link active" to="/">
                <span class="nav-icon">
                  <i class="fas fa-home"></i>
                </span>
                <span class="nav-link-text">Overview</span>
              </router-link>
              <!--//nav-link-->
            </li>
            <!--//nav-item-->

            <li class="nav-item has-submenu">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <a
                class="nav-link submenu-toggle"
                href="/categories"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-1"
                aria-expanded="false"
                aria-controls="submenu-1"
              >
                <span class="nav-icon">
                  <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                  <i class="fas fa-tags"></i>
                </span>
                <span class="nav-link-text">Categories</span>
                <span class="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                <!--//submenu-arrow-->
              </a>
              <!--//nav-link-->
              <div
                id="submenu-1"
                class="collapse submenu submenu-1"
                data-bs-parent="#menu-accordion"
              >
                <ul class="submenu-list list-unstyled">
                  <li class="submenu-item">
                    <router-link to="/categories/new" class="submenu-link"
                      >Create New</router-link
                    >
                  </li>
                  <li class="submenu-item">
                    <router-link to="/categories" class="submenu-link"
                      >Manage</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <!--//nav-item-->
            <li class="nav-item has-submenu">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <a
                class="nav-link submenu-toggle"
                href="/blogs"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-2"
                aria-expanded="false"
                aria-controls="submenu-2"
              >
                <span class="nav-icon">
                  <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                  <i class="far fa-newspaper"></i>
                </span>
                <span class="nav-link-text">Blogs</span>
                <span class="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                <!--//submenu-arrow-->
              </a>
              <!--//nav-link-->
              <div
                id="submenu-2"
                class="collapse submenu submenu-2"
                data-bs-parent="#menu-accordion"
              >
                <ul class="submenu-list list-unstyled">
                  <li class="submenu-item">
                    <router-link to="/blogs/new" class="submenu-link"
                      >Create New</router-link
                    >
                  </li>
                  <li class="submenu-item">
                    <router-link to="/blogs" class="submenu-link"
                      >Manage</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <!--//nav-item-->

            <li class="nav-item has-submenu">
              <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
              <a
                class="nav-link submenu-toggle"
                href="/pages"
                data-bs-toggle="collapse"
                data-bs-target="#submenu-3"
                aria-expanded="false"
                aria-controls="submenu-3"
              >
                <span class="nav-icon">
                  <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                  <i class="fas fa-book-open"></i>
                </span>
                <span class="nav-link-text">Pages</span>
                <span class="submenu-arrow">
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-chevron-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </span>
                <!--//submenu-arrow-->
              </a>
              <!--//nav-link-->
              <div
                id="submenu-3"
                class="collapse submenu submenu-3"
                data-bs-parent="#menu-accordion"
              >
                <ul class="submenu-list list-unstyled">
                  <li class="submenu-item">
                    <router-link to="/pages/new" class="submenu-link"
                      >Create New</router-link
                    >
                  </li>
                  <li class="submenu-item">
                    <router-link to="/pages" class="submenu-link"
                      >Manage</router-link
                    >
                  </li>
                </ul>
              </div>
            </li>
            <!--//nav-item-->
          </ul>
          <!--//app-menu-->
        </nav>
        <!--//app-nav-->
        <div class="app-sidepanel-footer">
          <nav class="app-nav app-nav-footer">
            <ul class="app-menu footer-menu list-unstyled">
              <li class="nav-item">
                <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                <router-link to="/settings" class="nav-link">
                  <span class="nav-icon">
                    <i class="fa fa-cog"></i>
                  </span>
                  <span class="nav-link-text">Settings</span>
                </router-link>
                <!--//nav-link-->
              </li>
              <!--//nav-item-->
              <li class="nav-item">
                <!--//Bootstrap Icons: https://icons.getbootstrap.com/ -->
                <a class="nav-link" :href="helpLink" target="_blank">
                  <span class="nav-icon">
                    <i class="far fa-question-circle"></i>
                  </span>
                  <span class="nav-link-text">Help</span>
                </a>
                <!--//nav-link-->
              </li>
              <!--//nav-item-->
            </ul>
            <!--//footer-menu-->
          </nav>
        </div>
        <!--//app-sidepanel-footer-->
      </div>
      <!--//sidepanel-inner-->
    </div>
    <!--//app-sidepanel-->
  </header>
  <!--//app-header-->
</template>

<script>
import { getSsoLogoutUrl } from "@/services/sso.service";

export default {
  name: "NavMenu",
  data() {
    return {
      org: localStorage.getItem("org"),
      helpLink: process.env.VUE_APP_HELP_URL
    };
  },
  methods: {
    onMenuClick() {
      document
        .getElementById("app-sidepanel")
        .classList.add("sidepanel-visible");
    },

    onMenuClose() {
      document
        .getElementById("app-sidepanel")
        .classList.remove("sidepanel-visible");
    },

    logOut() {
      localStorage.clear();
      window.location.replace(getSsoLogoutUrl());
    },

    switchOrg() {
      localStorage.removeItem("org");
      window.location.href = "/init";
    },
  },
};
</script>